<template>
  <div v-if="categories.length">
    <apexchart
      type="bar"
      :height="calculateChartHeight(categories)"
      :options="chartOptions"
      :series="series"
    ></apexchart>
  </div>
</template>
<script>
import { ref } from "vue";
import { getCSSVariableValue } from "@/assets/ts/_utils";
import {
  calculateChartHeight,
  formatNumberIntoHumanizeForm,
} from "@/utils/staticHelper";

export default {
  name: "Bar Chart With Negative Values",
  methods: { calculateChartHeight },
  props: {
    graphSeries: {
      type: Array,
      required: true,
    },
    showYAxis: {
      type: Boolean,
      default: true,
    },
    categories: {
      type: Array,
      required: true,
    },
    negative: {
      type: Boolean,
      required: false,
    },
    chartHeight: {
      type: Number,
      default: 400,
    },
  },
  setup(props) {
    const fontSize = ref("12px");
    const labelColor = getCSSVariableValue("--bs-gray-500");
    const borderColor = getCSSVariableValue("--bs-gray-200");
    const xAxis = ref(
      props.negative
        ? {
            min: props.negative ? Math.min(...props.graphSeries[0].data) : 0,
            max: 0,
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
              borderType: "solid",
              color: "rgb(111, 115, 144)",
            },
            labels: {
              rotate: -45,
              rotateAlways: true,
              hideOverlappingLabels: true,
              showDuplicates: false,
              trim: false,
              minHeight: 70,
              maxHeight: 500,
              style: {
                colors: labelColor,
                fontSize: fontSize.value,
                fontWeight: 400,
              },
              formatter: (value) => {
                return formatNumberIntoHumanizeForm(value, 1);
              },
            },
          }
        : {
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
              borderType: "solid",
              color: "rgb(111, 115, 144)",
            },
            labels: {
              rotate: -45,
              rotateAlways: true,
              hideOverlappingLabels: true,
              showDuplicates: false,
              trim: false,
              minHeight: 70,
              maxHeight: 500,
              style: {
                colors: labelColor,
                fontSize: fontSize.value,
                fontWeight: 400,
              },
              formatter: (value) => {
                return formatNumberIntoHumanizeForm(value, 1);
              },
            },
          }
    );
    const chartOptions = ref({
      chart: {
        type: "bar",
        height: calculateChartHeight(props.categories),
        stacked: false,
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      fill: {
        opacity: 1,
      },
      plotOptions: {
        bar: {
          horizontal: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: 2,
        colors: ["#fff"],
      },
      colors: ["#008FFB", "#FF4560"],
      grid: {
        borderColor: borderColor,
        strokeDashArray: 4,
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
      legend: {
        show: true,
        fontSize: fontSize.value,
        fontWeight: 400,
        labels: {
          colors: labelColor,
          useSeriesColors: false,
        },
      },
      markers: {
        strokeWidth: 3,
      },
      labels: props.categories,
      yaxis: {
        show: props.showYAxis,
        labels: {
          style: {
            colors: labelColor,
            fontSize: "12px",
          },
        },
        tooltip: {
          enabled: true,
        },
      },
      xaxis: xAxis.value,
      tooltip: {
        style: {
          fontSize: fontSize.value,
          fontWeight: 400,
        },
        y: {
          formatter: (value) => {
            return formatNumberIntoHumanizeForm(value, 1);
          },
        },
      },
    });
    const series = ref(props.graphSeries);
    return { series, chartOptions };
  },
};
</script>
<style lang="scss">
.apexcharts-legend-marker {
  border-radius: 12px !important;
}

.apexcharts-yaxis-inversed {
  text:last-child {
    display: none !important;
  }
}
</style>
