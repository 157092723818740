<template>
  <div>
    <div id="spotify_catalog_age_graph">
      <CardToolbar
        :show-toolbar-buttons="false"
        description=""
        title="Catalogue Age vs Total Streams"
        :fontStyle="true"
        :padding="false"
        margin-class="margin-bottom-12"
        :shadow-class="true"
        v-if="
          categories.length > 0 &&
          songsAgeByTotalStreams.length > 0 &&
          totalSpotifyStreams.length > 0 &&
          !loading
        "
      >
        <template v-slot:body>
          <div class="row">
            <div class="col-7">
              <NegativeValueChart
                :chart-height="400"
                :categories="categories"
                :graph-series="[
                  {
                    name: 'Song Age In Years',
                    data: songsAgeByTotalStreams,
                    color: '#FF4560',
                  },
                ]"
                :show-y-axis="true"
                :negative="Math.min(...songsAgeByTotalStreams) > -3"
              />
            </div>
            <div class="col-5" :style="'margin-left:' + marginLeft">
              <NegativeValueChart
                :chart-height="400"
                :categories="categories"
                :graph-series="[
                  {
                    name: 'Total Spotify Streams',
                    data: totalSpotifyStreams,
                    color: '#008FFB',
                  },
                ]"
                :show-y-axis="false"
                :negative="false"
              />
            </div>
          </div>
        </template>
      </CardToolbar>
    </div>
    <div v-if="!public" id="age_vs_streams_last_month">
      <CardToolbar
        :show-toolbar-buttons="false"
        description=""
        title="Catalogue Age vs Streams Of Last Month"
        margin-class="margin-bottom-12"
        :fontStyle="true"
        :padding="false"
        :shadow-class="true"
        v-if="
          categoriesOrderByMonthlyStreams.length > 0 &&
          lastMonthSpotifyStreamsByMonthlyStreams.length > 0 &&
          songsAgeByMonthlyStreams.length > 0 &&
          !loading
        "
      >
        <template v-slot:body>
          <div class="row">
            <div class="col-7">
              <NegativeValueChart
                :chart-height="400"
                :categories="categoriesOrderByMonthlyStreams"
                :graph-series="[
                  {
                    name: 'Song Age In Years',
                    data: songsAgeByMonthlyStreams,
                    color: '#FF4560',
                  },
                ]"
                :show-y-axis="true"
                :negative="Math.min(...songsAgeByMonthlyStreams) > -3"
              />
            </div>
            <div class="col-5" :style="'margin-left:' + marginLeft">
              <NegativeValueChart
                :chart-height="400"
                :categories="categories"
                :graph-series="[
                  {
                    name: 'Streams of Last Month',
                    data: lastMonthSpotifyStreamsByMonthlyStreams,
                    color: '#008FFB',
                  },
                ]"
                :show-y-axis="false"
                :negative="false"
              />
            </div>
          </div>
        </template>
      </CardToolbar>
    </div>
    <div v-if="!public" id="total_vs_streams_last_month">
      <CardToolbar
        :show-toolbar-buttons="false"
        description=""
        title="Total Streams vs Streams Of Last Month"
        :margin-class="bottomMarginClass"
        :fontStyle="true"
        :padding="false"
        :shadow-class="true"
        v-if="
          categories.length > 0 &&
          lastMonthSpotifyStreamsByTotalStreams.length > 0 &&
          totalSpotifyStreams.length > 0 &&
          !loading
        "
      >
        <template v-slot:body>
          <div class="row">
            <div class="col-7">
              <NegativeValueChart
                :chart-height="400"
                :categories="categories"
                :graph-series="[
                  {
                    name: 'Total Spotify Streams',
                    data: totalSpotifyStreams.map((val) => -1 * val),
                    color: '#FF4560',
                  },
                ]"
                :show-y-axis="true"
                :negative="false"
              />
            </div>
            <div class="col-5" :style="'margin-left:' + marginLeft">
              <NegativeValueChart
                :chart-height="400"
                :categories="categories"
                :graph-series="[
                  {
                    name: 'Streams of Last Month',
                    data: lastMonthSpotifyStreamsByTotalStreams,
                    color: '#008FFB',
                  },
                ]"
                :show-y-axis="false"
                :negative="false"
              />
            </div>
          </div>
        </template>
      </CardToolbar>
    </div>
  </div>
</template>

<script>
import { ref, watch } from "vue";
import * as moment from "moment";
import CardToolbar from "@/common/components/CardToolbar.vue";
import NegativeValueChart from "@/artists/components/BarChartWithNegativeValues";
import {
  calculateChartHeight,
  getUniqueValuesFromArray,
} from "@/utils/staticHelper";

export default {
  name: "TrackCatalogue",
  methods: { calculateChartHeight },
  components: {
    CardToolbar,
    NegativeValueChart,
  },
  props: {
    tracks: {
      type: Array,
      required: true,
    },
    marginLeft: {
      type: String,
      default: "56px",
    },
    bottomMarginClass: {
      type: String,
      default: "mb-5 mb-xl-10",
    },
    public: {
      type: Boolean,
      required: false,
      default:false,
    },
  },
  setup(props) {
    const loading = ref(false);
    const catalogueTracks = ref([]);
    const songsAgeByTotalStreams = ref([]);
    const totalSpotifyStreams = ref([]);
    const lastMonthSpotifyStreamsByTotalStreams = ref([]);
    const songsAgeByMonthlyStreams = ref([]);
    const lastMonthSpotifyStreamsByMonthlyStreams = ref([]);
    const categories = ref([]);
    const categoriesOrderByMonthlyStreams = ref([]);
    const createGraphResponse = () => {
      categories.value = [];
      totalSpotifyStreams.value = [];
      songsAgeByTotalStreams.value = [];
      lastMonthSpotifyStreamsByTotalStreams.value = [];
      categoriesOrderByMonthlyStreams.value = [];
      songsAgeByMonthlyStreams.value = [];
      lastMonthSpotifyStreamsByMonthlyStreams.value = [];

      catalogueTracks.value = catalogueTracks.value.sort(
        (a, b) => b.spotifyStreams - a.spotifyStreams
      );
      catalogueTracks.value = getUniqueValuesFromArray(props.tracks, "track");

      for (const song of catalogueTracks.value) {
        if (
          song.artists &&
          song.releaseDate &&
          song.spotifyStreams &&
          song.spotifyStreamsOfLastMonth
        ) {
          categories.value.push(song.track);
          const age =
            moment().diff(moment(song.releaseDate), "years") >= 1
              ? moment().diff(moment(song.releaseDate), "years")
              : moment().diff(moment(song.releaseDate), "days") / 365;
          songsAgeByTotalStreams.value.push(-1 * age);
          totalSpotifyStreams.value.push(song.spotifyStreams);
          lastMonthSpotifyStreamsByTotalStreams.value.push(
            song.spotifyStreamsOfLastMonth
          );
        }
      }

      const tracks2 = catalogueTracks.value.sort(
        (a, b) => b.spotifyStreamsOfLastMonth - a.spotifyStreamsOfLastMonth
      );
      for (const song of tracks2) {
        if (
          song.artists &&
          song.releaseDate &&
          song.spotifyStreams &&
          song.spotifyStreamsOfLastMonth
        ) {
          categoriesOrderByMonthlyStreams.value.push(song.track);
          const age =
            moment().diff(moment(song.releaseDate), "years") >= 1
              ? moment().diff(moment(song.releaseDate), "years")
              : moment().diff(moment(song.releaseDate), "days") / 365;
          songsAgeByMonthlyStreams.value.push(-1 * age);
          lastMonthSpotifyStreamsByMonthlyStreams.value.push(
            song.spotifyStreamsOfLastMonth
          );
        }
      }
      loading.value = false;
    };

    watch(
      () => props.tracks,
      (value) => {
        if (value && value.length) {
          loading.value = true;
          catalogueTracks.value = value;
          createGraphResponse();
        }
      },
      { immediate: true }
    );

    return {
      loading,
      songsAgeByTotalStreams,
      totalSpotifyStreams,
      songsAgeByMonthlyStreams,
      lastMonthSpotifyStreamsByMonthlyStreams,
      lastMonthSpotifyStreamsByTotalStreams,
      categoriesOrderByMonthlyStreams,
      categories,
    };
  },
};
</script>
